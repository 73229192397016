[ data-page-name = "attendees" ] section {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 2rem;
}

[ data-page-name = "attendees" ] #collection > * { aspect-ratio: initial; }

@media screen and (orientation: portrait) {
    [ data-page-name = "attendees" ] #collection { grid-template-columns: 1fr !important; }
}