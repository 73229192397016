[ data-page-name = "payments" ] section .payment {
    align-items: center;
    display: flex;
    gap: 1rem;
}
[ data-page-name = "payments" ] section .payment > .vendor {
    height: 3rem;
    width: 3rem;
}
[ data-page-name = "payments" ] section .payment > .vendor > img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}

@media screen and (orientation: portrait) {
    [ data-page-name = "payments" ] section .payment > .vendor {
        height: 2rem;
        width: 2rem;
    }
}