[ data-page-name = "dashboard" ] section {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

[ data-page-name = "dashboard" ] section .chart {
    background: rgb(202, 224, 167);
    border-radius: 0.5rem;
    padding: 0.5rem;
}

[ data-page-name = "dashboard" ] section > * {
    margin-top: 5rem;
    padding: 1rem;
    position: relative;
}
/* [ data-page-name = "dashboard" ] section > :nth-child(odd) {
    background: rgb(192, 152, 152);
    border-radius: 1rem;
} */
[ data-page-name = "dashboard" ] section > * > .heading {
    font-size: 1.75rem;
    font-weight: 750;
    position: absolute;
    top: -2.5rem;
}


/* events and tickets division */
[ data-page-name = "dashboard" ] section > #events-and-tickets {
    display: flex;
    gap: 2rem;
}
[ data-page-name = "dashboard" ] section > #events-and-tickets > * {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
[ data-page-name = "dashboard" ] section > #events-and-tickets > aside > .summary {
    display: flex;
    gap: 1rem;
}
[ data-page-name = "dashboard" ] section > #events-and-tickets > aside > .summary > * {
    background: var(--theme-color-10);
    border-radius: 0.5rem;
    flex: 1 1 0px;
    font-weight: 500;
    padding: 1rem;
    text-align: center;
}
[ data-page-name = "dashboard" ] section > #events-and-tickets > aside > .list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
}
[ data-page-name = "dashboard" ] section > #events-and-tickets > main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
[ data-page-name = "dashboard" ] section > #events-and-tickets > main > :nth-child(2) {
    border-radius: 1rem;
    flex-grow: 1;
}


/* market division */
[ data-page-name = "dashboard" ] section > #market {
    display: grid;
    gap: 1rem;
}
[ data-page-name = "dashboard" ] section > #market > * {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* users division */
[ data-page-name = "dashboard" ] section > #users {
    display: flex;
    gap: 1rem;
}
[ data-page-name = "dashboard" ] section > #users > * { flex: 1 1 0px; }



/* transactions division */
[ data-page-name = "dashboard" ] section > #transactions {
    display: grid;
    gap: 1rem;
}
[ data-page-name = "dashboard" ] section > #transactions > * {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
[ data-page-name = "dashboard" ] section > #transactions > .successful > .emphasize { font-weight: 750; }
[ data-page-name = "dashboard" ] section > #transactions > .attempts > .emphasize { font-weight: 550; }
[ data-page-name = "dashboard" ] section > #transactions > .more {
    background: var(--theme-color-10);
    color: white;
    cursor: pointer;
}



@media screen and (orientation: landscape) {
    [ data-page-name = "dashboard" ] section { padding: 2rem; }

    [ data-page-name = "dashboard" ] section > * {
        max-height: 75vh;
        min-height: 50vh;
    }


    /* events and tickets division */
    [ data-page-name = "dashboard" ] section > #events-and-tickets { height: 62.5vh; }

    [ data-page-name = "dashboard" ] section > #events-and-tickets > aside { flex: 1 0 0; }

    [ data-page-name = "dashboard" ] section > #events-and-tickets > main { flex: 1.5 0 0; }

    
    /* market division */
    [ data-page-name = "dashboard" ] section > #market {
        grid-template-columns: 60% 40%;
        grid-template-rows: 1.5fr 1fr;
        height: 62.5vh;
    }
    [ data-page-name = "dashboard" ] section > #market > .chart-container {
        grid-column-end: 2;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-row-start: 1;
    }


    /* transactions */
    [ data-page-name = "dashboard" ] section > #transactions {
        grid-template-columns: 60% 40%;
        grid-template-rows: 1.5fr 1fr;
    }
    [ data-page-name = "dashboard" ] section > #transactions > .successful {
        grid-column-end: 2;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-row-start: 1;
    }
    [ data-page-name = "dashboard" ] section > #transactions > .successful > .emphasize { font-size: 3rem; }
    [ data-page-name = "dashboard" ] section > #transactions > .attempts {
        grid-column-end: 3;
        grid-column-start: 2;
        grid-row-end: 2;
        grid-row-start: 1;
    }
    [ data-page-name = "dashboard" ] section > #transactions > .attempts > .emphasize { font-size: 1.5rem; }
}



@media screen and (orientation: landscape) and (max-width: 1081px) {
    [ data-page-name = "dashboard" ] section > * > .heading { font-size: 1.25rem; }

    /* events and tickets division */
    [ data-page-name = "dashboard" ] section > #events-and-tickets {
        flex-direction: column;
        height: initial;
        max-height: initial;
    }
    [ data-page-name = "dashboard" ] section > #events-and-tickets > main {
        flex: initial;
        height: 95vh;
    }


    /* market division */
    [ data-page-name = "dashboard" ] section > #market {
        flex-direction: column;
        height: initial;
        max-height: initial;
    }

    
    /* transactions */
    [ data-page-name = "dashboard" ] section > #transactions {
        font-size: 0.85rem !important;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    [ data-page-name = "dashboard" ] section > #transactions > .successful > .emphasize { font-size: 2rem; }
    [ data-page-name = "dashboard" ] section > #transactions > .attempts > .emphasize { font-size: 1.25rem; }
    [ data-page-name = "dashboard" ] section > #transactions > .more { height: 10vh; }
}



@media screen and (orientation: portrait) {
    [ data-page-name = "dashboard" ] section { padding: 1rem; }
    [ data-page-name = "dashboard" ] section .chart {
        height: 25vh;
    }
    [ data-page-name = "dashboard" ] section > * > .heading { font-size: 1.25rem; }
    

    /* events and tickets division */
    [ data-page-name = "dashboard" ] section > #events-and-tickets { flex-direction: column; }
    [ data-page-name = "dashboard" ] section > #events-and-tickets > main { height: 37.5vh; }


    /* market division */
    [ data-page-name = "dashboard" ] section > #market { flex-direction: column; }


    /* transactions */
    [ data-page-name = "dashboard" ] section > #transactions {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    [ data-page-name = "dashboard" ] section > #transactions > .successful > .emphasize { font-size: 2rem; }
    [ data-page-name = "dashboard" ] section > #transactions > .attempts { font-size: 1rem; }
    [ data-page-name = "dashboard" ] section > #transactions > .attempts > .emphasize { font-size: 1.25rem; }
    [ data-page-name = "dashboard" ] section > #transactions > .more { height: 10vh; }

    
    /* users division */
    [ data-page-name = "dashboard" ] section > #users { flex-direction: column; }
}