.layout.auth,
.layout.default {
    background: var(--theme-background);
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
}


/* auth */
.layout.auth > #content { height: 100%; }


/* default */
.layout.default > #content > section {
    display: flex;
    flex-direction: column;
}
.layout.default.panneled > #content > section#pannel {
    background: rgb(255, 255, 255);
    height: 100%;
    position: fixed;
    right:calc(-1 * var(--CONTENT-PANEL-WIDTH));
    top: 0;
    width: var(--CONTENT-PANEL-WIDTH);
}
.layout.default.panneled.show-pannel > #content > section#pannel {
    right: 0;
    z-index: 100;
}


/* all landscape */
@media screen and (orientation: landscape) {
    .layout.default { display: inline-block; }

    .layout.default > * {
        height: 100%;
        float: left;
    }

    .layout.default > #nav {
        position: sticky;
        top: 0;
        width: var(--NAV-PANEL-WIDTH);
    }
    
    .layout.default > #content { width: calc(100% - var(--NAV-PANEL-WIDTH)); }

    .layout.default > #content > section {
        gap: 3rem;
        padding: 2rem;
    }

    .layout.default > #content > section:only-child,
    .layout.default.panneled > #content > section#main {
        padding-right: calc(20vw + 2rem);
        width: 100%;
    }

    .layout.default.panneled.show-pannel > #content > section#main {
        padding-right: 2rem;
        width: calc(100% - 20vw);
    }

    
    .layout.default.panneled > #content {
        display: flex;
        overflow-x: hidden;
        white-space: nowrap;
    }
    .layout.default.panneled > #content > * {
        flex-shrink: 0;
        overflow-y: auto;
    }
}


/* small width landscape (mobile landscape) */
@media screen and (orientation: landscape) and (max-width: 1081px) {
    .layout.default > #content >  section {
        gap: 3rem;
        padding: 1rem;
    }

    .layout.default > #content > section:only-child,
    .layout.default.panneled > #content > section#main { padding-right: 2rem; }

    .layout.default.panneled.show-pannel > #content > section#main { width: 100%; }

    .layout.default.panneled > #content > * { overflow-y: auto; }
}


/* all portrait */
@media screen and (orientation: portrait) {
    .layout.default > #content >  section {
        gap: 3rem;
        padding: 1rem;
    }
}