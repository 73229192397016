[ data-page-name = "vendor_details" ] section > .quick-products > .summary { display: grid; }
[ data-page-name = "vendor_details" ] section > .quick-products > .summary > * { overflow: hidden; }

[ data-page-name = "vendor_details" ] section > .stats > .summary {
    display: flex;
    gap: 1rem;
}
[ data-page-name = "vendor_details" ] section > .stats > .summary > * {
    aspect-ratio: 1/1;
    border-radius: 0.5rem;
    flex: 1 1 0px;
    width: 100%;
}

@media screen and (orientation: landscape) {
    [ data-page-name = "vendor_details" ] section > .quick-products > .summary {
        gap: 1rem;
        grid-template-columns: 65% 35%;
        grid-template-rows: repeat(2, 1fr);
        height: 50vh;
    }
    [ data-page-name = "vendor_details" ] section > .quick-products > .summary > .newest {
        grid-column-end: 2;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-row-start: 1;
    }
    [ data-page-name = "vendor_details" ] section > .quick-products > .summary > .top {
        grid-column-end: 3;
        grid-column-start: 2;
        grid-row-end: 2;
        grid-row-start: 1;
    }
    [ data-page-name = "vendor_details" ] section > .quick-products > .summary > .updated {
        grid-column-end: 3;
        grid-column-start: 2;
        grid-row-end: 3;
        grid-row-start: 2;
    }
    [ data-page-name = "vendor_details" ] section > .quick-products > .summary > :only-child {
        grid-column-end: 3;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-row-start: 1;
    }
}

@media screen and (orientation: portrait) {
    [ data-page-name = "vendor_details" ] section > .stats > .summary { flex-direction: column; }
}