.form {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    height: 100%;
}

.form > .head {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.form > form {
    flex-grow: 1;
}
.form > form > .screen {
    display: none;
    flex-shrink: 0;
    height: 100%;
    padding-bottom: 2rem;
}
.form > form > .screen.in-view {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}
.form > form > .screen .controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.form > form > .screen .controls > * {
    background-color: greenyellow;
    padding: 0.35rem 1rem;
}
.form > form > .screen .fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}