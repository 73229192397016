:root {
    --CONTENT-PANEL-WIDTH: 100%;
    --NAV-PANEL-WIDTH: 15vw;
    --THEME-BACKGROUND: rgb(214, 212, 224);
    --THEME-NAV-BACKGROUND: var(--theme-color-30);
    --THEME-10: rgb(25, 0, 255);

    --LAYER-NAV-HEAD: 25;
    --LAYER-NAV-BODY: 24;
}


/* screen landscape */
@media screen and (orientation: landscape) {
    :root { --CONTENT-PANEL-WIDTH: 20vw; }
}


/* small screen landscape (mobile landscape) */
@media screen and (orientation: landscape) and (max-width: 1081px) {
    :root {
        --CONTENT-PANEL-WIDTH: 50%;
        --NAV-PANEL-WIDTH: 25%;
    }
}