a {
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* shared styles */
.title {
  font-size: 1.125rem;
  font-weight: 500;
}

#collection-head {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
#collection-head > .cta {
  background: var(--theme-color-10);
  border-radius: 2rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
#collection-head > .toggler {
  align-items: center;
  color: var(--theme-text-disabled);
  display: flex;
  gap: 1rem;
}
#collection-head > .toggler > .active { color: var(--theme-text-default); }

#collection.grid {
  display: grid;
  gap: 2rem;
  grid-template-rows: auto;
}
#collection.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.id-brief {
  display: flex;
  flex-direction: column;
}
.id-brief > .name {
  font-size: 1.5rem;
  font-weight: 500;
}
.id-brief > .controls {
  align-items: center;
  display: flex;
  gap: 1rem;
  padding-block: 0.5rem;
  position: relative;
}
.id-brief > .controls > .cta {
  background: var(--theme-color-10);
  border-radius: 2rem;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0;
}



.input-group {
  align-items: center;
  display: flex;
  gap: 1rem;
}
.input-group > * { flex: 1 0 0px; }

@media screen and (orientation: landscape) {
  #collection.grid { grid-template-columns: repeat(4, 1fr); }
  #collection.grid > * { border-radius: 0.5rem; }
}



@media screen and (orientation: landscape) and (max-width: 1081px) {
  #collection.grid {
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
  }
}


@media screen and (orientation: portrait) {
  #collection.grid {
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
  }
}