[ data-page-name = "event_details" ] section#main > .banner {
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
}
[ data-page-name = "event_details" ] section#main > .banner > .flier {
    background: rgba(255, 255, 255, 0.199);
    backdrop-filter: blur(5);
    height: 100%;
    position: relative;
    width: 100%;
}
[ data-page-name = "event_details" ] section#main > .banner > .flier::after {
    background-image: url("../images/PXL_20220909_235830633.PORTRAIT.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
[ data-page-name = "event_details" ] section#main > .banner > .flier > img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}
[ data-page-name = "event_details" ] section#main > .banner > .cta {
    background: var(--theme-color-10);
    border-radius: 2rem;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
}

[ data-page-name = "event_details" ] section#main > .details {
    display: flex;
}

[ data-page-name = "event_details" ] section#main > .details .headline {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
[ data-page-name = "event_details" ] section#main > .details .headline > :not(.title) { font-size: 0.85rem; }

[ data-page-name = "event_details" ] section#main > .details .list { display: flex; }
[ data-page-name = "event_details" ] section#main > .details .list.h { flex-wrap: wrap; }
[ data-page-name = "event_details" ] section#main > .details .list.v { flex-direction: column; }

[ data-page-name = "event_details" ] section#main > .details > * {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

@media screen and (orientation: landscape) {
    [ data-page-name = "event_details" ] section#main > .banner {
        flex-shrink: 0;
        height: 50vh;
    }
    
    [ data-page-name = "event_details" ] section#main > .details {
        gap: 3rem;
        padding: 1rem 6rem;
    }
    [ data-page-name = "event_details" ] section#main > .details .list { gap: 0.5rem; }
    [ data-page-name = "event_details" ] section#main > .details * { white-space: wrap; }
    [ data-page-name = "event_details" ] section#main > .details > .left { flex: 1.375 0 0px; }
    [ data-page-name = "event_details" ] section#main > .details > .right { flex: 1 0 0px; }
}

@media screen and (orientation: landscape) and (max-width: 1081px) {
    [ data-page-name = "event_details" ] section#main > .banner { height: 75vh; }
    [ data-page-name = "event_details" ] section#main > .details {
        flex-direction: column;
        gap: 5rem;
        padding: 1rem;
    }
    
    [ data-page-name = "event_details" ] section#main > .details .list.h.n-wrap {
        flex-wrap: nowrap;
        overflow-x: auto;
    }
}

@media screen and (orientation: portrait) {
    [ data-page-name = "event_details" ] section#main > .banner { height: 30vh; }
    
    [ data-page-name = "event_details" ] section#main > .details {
        flex-direction: column;
        gap: 5rem;
        padding: 1rem;
    }
    [ data-page-name = "event_details" ] section#main > .details .list { gap: 0.25rem; }

    [ data-page-name = "event_details" ] section#main > .details .list.h.n-wrap {
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    [ data-page-name = "event_details" ] section#main > .details .list.h.n-wrap > * { flex-shrink: 0; }
}