form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

form * {
    font-size: 0.85rem;
    outline: none;
}

/* buttons and input */
button,
input:not([ type = "checkbox" ], [ type = "radio" ]),
textarea,
.input-field .options > * {
    background: rgba(0, 0, 0, 0.521);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
}

input:not([ type = "checkbox" ], [ type = "radio" ]),
textarea,
.input-field .options > * { border: none; }

/* button */
button {
    border: none;
    color: black;
    cursor: pointer;
    width: fit-content;
}

/* text based inputs */
input:not([ type = "checkbox" ], [ type = "radio" ]) { cursor: text; }
textarea {
    height: 7.5rem;
    resize: none;
}

/* click based inputs */
input[ type = "checkbox" ], input[ type = "radio" ] { display: none; }

/* file inputs */
input[ type = "file" ] { display: none; }

/* divider */
.divider {
    background: var(--THEME-NAV-BACKGROUND);
    height: 0.125rem;
    margin-block: 2rem;
}

/* input fields */
.input-field {
    display: flex;
    flex-direction: column;
    font-size: 0.85rem;
    gap: 0.33rem;
}
.input-field .file-uploader {
    align-items: center;
    align-self: center;
    border: 0.25rem solid var(--THEME-NAV-BACKGROUND);
    border-radius: 1rem;
    display: flex;
    font-size: 1.125rem;
    font-weight: 750;
    height: 10rem;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    width: 10rem;
}
.input-field .options {
    align-items: center;
    display: flex;
    gap: 1rem;
}
.input-field .options > * {
    cursor: pointer;
    overflow: hidden;
    position: relative;
}
.input-field .options > .selected::before {
    background: rgba(0, 0, 0, 0.1666);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@media screen and (orientation: landscape) and (min-width: 1081px) {
    form { width: 100%; }

    /* input fields */
    .input-field { width: 100%; }
}