[ data-page-name = "coordinator_details" ] section > .picture {
    border-radius: 1rem;
    height: 10rem;
    overflow: hidden;
    width: 10rem;
}
[ data-page-name = "coordinator_details" ] section > .picture > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}