/* dark theme related variables */
@media (prefers-color-scheme: light) {
    :root {
        --theme-background: #001f33; /* brand 10 */
        --theme-button-background: #ccebff; /* brand 90 */
        --theme-button-text: #1a1a1a; /* 10 */
        --theme-color-10: #0087e2; /* brand */
        --theme-color-60: #000f1a; /* brand 5 */
        --theme-text-bold: #ffffff; /* 100 */
        --theme-text-default: #e6e6e6; /* 90 */
        --theme-text-inactive: #b3b3b3; /* 70 */
        --theme-text-light: #cccccc; /* 80 */
        background: var(--theme-background);
        color-scheme: dark;
        color: var(--theme-text-default);
    }

    img, video { filter: brightness(.8) contrast(1.2); }
}

/* light theme related variables */
@media (prefers-color-scheme: dark) {
    :root {
        --theme-background: rgba(204, 235, 255, 0.125); /* brand 90 */
        --theme-button-background: #001f33; /* brand 10 */
        --theme-button-text: #e6e6e6; /* 90 */
        --theme-color-10: #0087e2; /* brand */
        --theme-color-30: #e6f5ff; /* brand 95 */
        --theme-color-60: #ccebff; /* brand 90 */
        --theme-text-bold: #000000; /* 0 */
        --theme-text-default: #1a1a1a; /* 10 */
        --theme-text-inactive: #4d4d4d; /* 30 */
        --theme-text-light: #333333; /* 20 */
        background: var(--theme-background);
        color-scheme: light;
        color: var(--theme-text-default);
    }

    img, video { filter: initial; }
}