[ data-page-name = "log_in" ] section {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}
[ data-page-name = "log_in" ] section #form {
    background: var(--theme-color-60);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

@media screen and (orientation: landscape) {
    [ data-page-name = "log_in" ] section #form { width: 25%; }
}

@media screen and (orientation: landscape) and (max-width: 1081px) {
    [ data-page-name = "log_in" ] section #form { width: 85%; }
}

@media screen and (orientation: portrait) {
    [ data-page-name = "log_in" ] section #form { width: 85%; }
}