.card {
    background: var(--theme-color-60);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 100%;
}


/* attendee/ coordinator */
.card.attendee,
.card.coordinator {
    border-radius: 5rem !important;
    flex-direction: row;
}
.card.attendee > .image,
.card.coordinator > .image {
    background: var(--THEME-BACKGROUND);
    border-radius: 5rem;
    flex-shrink: 0;
    overflow: hidden;
}
.card.attendee > .image > img,
.card.coordinator > .image > img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}
.card.attendee > .details,
.card.coordinator > .details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
}
.card.attendee > .details > .controls,
.card.coordinator > .details > .controls {
    align-items: center;
    display: flex;
    font-size: 0.85rem;
    gap: 0.5rem;
}
.card.coordinator > .details > .controls > :nth-child(2) { color: red; }
.card.attendee > .details .name,
.card.coordinator > .details .name { font-weight: 550; }


/* event */
.card.event { aspect-ratio: 1/1.125; }
.card.event > .date {
    /* color: rgba(0, 0, 0, 0.5); */
    position: relative;
}
.card.event > .date::after {
    background: var(--theme-color-10);
    border-radius: 0.25rem;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    top: calc(100% + 0.25rem);
    width: 7.5%;
}
.card.event > .venue {
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.925rem;
}
.card.event > .flier {
    border-radius: 0.5rem;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
}
.card.event > .flier > .banner {
    background: black;
    height: 100%;
    width: 100%;
}
.card.event > .flier > .banner > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.card.event > .flier > .name {
    background: linear-gradient(to top, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.125), transparent);
    bottom: 0;
    color: var(--theme-color-10);
    left: 0;
    position: absolute;
    width: 100%;
}


/* event-flier */
.card.event-flier {
    background: rgba(255, 255, 255, 0.199);
    backdrop-filter: blur(5);
    height: 100%;
    position: relative;
    width: 100%;
}
.card.event-flier::after {
    background: var(--backdrop);
    background-image: url("../images/PXL_20220909_235830633.PORTRAIT.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}
.card.event-flier > img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}


/* event-ticket-sales */
.card.event-ticket-sales {
    flex-direction: row;
    gap: 1rem;
}
.card.event-ticket-sales > .image {
    border-radius: 0.5rem;
    width: 25%;
}
.card.event-ticket-sales > .image > img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}
.card.event-ticket-sales > .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-block: 2rem;
}
.card.event-ticket-sales > .details > .name {
    font-size: 1.125rem;
    font-weight: 500;
}


/* organiser */
.card.organiser {
    background: transparent;
    height: 5rem;
    width: fit-content;
}
.card.organiser > img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}


/* product */
.card.product {
    gap: 1.5rem;
    padding: 1.5rem;
}
.card.product > .image {
    flex: 2 0 0px;
    overflow: hidden;
}
.card.product > .image img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}
.card.product > .briefing {
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}
.card.product > .briefing > .name { font-weight: 500; }
.card.product > .briefing > .price-n-inventory {
    align-items: center;
    display: flex;
    justify-content: space-between;
}


/* product-ad */
.card.product-ad {
    background: transparent;
    height: 100%;
    position: relative;
    width: 90%;
}
.card.product-ad > .image,
.card.product-ad > .image > img {
    height: 100%;
    width: 100%;
}
.card.product-ad > .image { padding: 1rem; }
.card.product-ad > .image > img { object-fit: scale-down; }
.card.product-ad > .description {
    background-color: rgba(133, 182, 255, 0.363);
    border-radius: 0.5rem;
    bottom: 25%;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    width: 75%;
}
.card.product-ad > .description > .brief { font-weight: 350; }
.card.product-ad > .description > .name { font-weight: 500; }


/* product-record */
.card.product-record {
    flex-direction: row;
    gap: 1.5rem;
    height: 4.5rem;
    padding: 0.5rem;
}
.card.product-record > .image-container {
    flex: 1 0 0;
    height: 100%;
}
.card.product-record > .image img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}
.card.product-record > .text {
    display: flex;
    flex: 3 0 0;
    flex-direction: column;
    justify-content: space-between;
}
.card.product-record > .text > .name { font-weight: 500; }
.card.product-record > .text > .date {
    align-items: center;
    display: flex;
    justify-content: space-between;
}


/* purchase */
.card.purchase > .image {
    align-items: center;
    display: flex;
    height: 10rem;
    justify-content: center;
    overflow: hidden;
}
.card.purchase > .image > img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}
.card.purchase > .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card.purchase > .text span {
    font-size: 0.85rem;
    font-weight: 350;
    margin-right: 1rem;
}


/* session and transaction */
.card.session,
.card.transaction {
    background: transparent;
    flex-direction: row;
    gap: 0.5em;
    padding: 0.5em 1em;
}
.card.session:nth-child(even),
.card.transaction:nth-child(even) {
    background-color: var(--theme-color-10);
    color: white;
}


/* session */
.card.session > .datetime { flex: 1.5 0 0; }
.card.session > .location { flex: 1 0 0; }
.card.session > .client { flex: 1 0 0; }
.card.session > .duration { flex: 1 0 0; }


/* speaker */
.card.speaker {
    background: transparent;
    height: 12.5rem;
    width: 12.5rem;
}
.card.speaker > * { text-align: center; }
.card.speaker > .image {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    overflow: hidden;
}
.card.speaker > .image > img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}
.card.speaker > .text { height: 50%; }
.card.speaker > .text > .name { font-weight: 500; }
.card.speaker > .text > .organisation  { font-size: 0.85rem; }


/* transaction */
.card.transaction > .id { flex: 1 0 0; }
.card.transaction > .datetime { flex: 1 0 0; }
.card.transaction > .amount { flex: 1 0 0; }
.card.transaction > .sender { flex: 1 0 0; }
.card.transaction > .receiver { flex: 1 0 0; }
.card.transaction > .status { flex: 0.5 0 0; }
.card.transaction > .reason { flex: 1 0 0; }


/* user */
.card.user {
    align-items: center;
    color: initial;
    flex-direction: row;
    gap: 0.5rem;
    padding: 0.5rem;
}
.card.user > .profile-image {
    flex-shrink: 0;
    max-width: 35%;
    min-width: 35%;
    width: 35%;
}
.card.user > .profile-image > img { width: 100%; }
.card.user > .brief-and-controls { flex-grow: 1; }
.card.user > .brief-and-controls > .name {
    font-size: 1rem;
    font-weight: 500;
}


/* vendor */
.card.vendor {
    aspect-ratio: 16/12;
    padding: 1.5rem 1rem;
    position: relative;
}
.card.vendor > .details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.card.vendor > .details > .logo {
    flex-grow: 1;
    overflow: hidden;
}
.card.vendor > .details > .logo > img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}
.card.vendor > .details > .name {
    font-size: 1.125rem;
    font-weight: 500;
}
.card.vendor > .details > .category { font-size: 0.875rem; }
.card.vendor > .handles {
    /* background: var(--THEME-NAV-BACKGROUND); */
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
}
.card.vendor > .handles > * {
    display: flex;
    gap: 0.25rem;
}
.card.vendor > .handles > * > * > img { height: 1em; }


/* all landscape */
@media screen and (orientation: landscape) {
    /* attendee/ coordinator */
    .card.attendee,
    .card.coordinator { padding: 0.5rem 2.5rem 0.5rem 0.5rem; }
    .card.attendee > .image,
    .card.coordinator > .image {
        height: 5rem;
        width: 5rem;
    }
    .card.attendee > .details,
    .card.coordinator > .details { padding-block: 0.5rem; }
    .card.attendee > .details > .name,
    .card.coordinator > .details > .name { font-size: 1.125rem; }


    /* event */
    .card.event { padding: 2rem 1rem 1rem; }
    .card.event > .date {
        font-size: 1.5rem;
        font-weight: 750;
    }
    .card.event > .flier { margin-top: 2rem; }
    .card.event > .flier > .name {
        font-size: 1.125rem;
        font-weight: 500;
        padding: 0.5rem;
    }


    /* event-ticket-sales */
    .card.event-ticket-sales { padding: 0.5rem; }


    /* purchases */
    .card.purchase {
        flex-direction: row;
        gap: 1rem;
        padding: 1rem;
    }
    .card.purchase > .image { width: 10rem; }
    .card.purchase > .text { justify-content: space-between; }


    /* vendor */
    .card.vendor { flex-direction: row !important; }
    .card.vendor > * { height: 100%; }
    .card.vendor > .handles { flex-direction: column; }
    .card.vendor > .handles > * { flex-direction: column; }
}


/* small width landscape (mobile landscape) */
@media screen and (orientation: landscape) and (max-width: 1081px) {
    /* attendee/ coordinator */
    .card.attendee,
    .card.coordinator { padding: 0.35rem 1.75rem 0.35rem 0.35rem; }
    .card.attendee > .image,
    .card.coordinator > .image {
        height: 3.75rem;
        width: 3.75rem;
    }
    .card.attendee > .details > .name,
    .card.coordinator > .details > .name { font-size: 1rem; }


    /* event */
    .card.event {
        aspect-ratio: initial;
        padding: 1.5rem 0.5rem 0.5rem;
    }
    .card.event > .date {
        font-size: 1rem;
        font-weight: 750;
    }
    .card.event > .venue { font-size: 0.8rem; }
    .card.event > .date::after {
        height: 0.2rem;
        width: 12.5%;
    }
    .card.event > .flier {
        height: 6.75rem;
        margin-top: 1rem;
    }
    .card.event > .flier > .name {
        font-size: 1rem;
        font-weight: 450;
        padding: 0.25rem;
    }


    /* event-ticket-sales */
    .card.event-ticket-sales { padding: 0.25rem; }
    .card.event-ticket-sales > .details { padding-block: 0.5rem; }


    /* product */
    .card.product { padding: 0.5rem; }
    .card.product > .briefing { height: 40%; }


    /* vendor */
    .card.vendor { aspect-ratio: initial; }
    .card.vendor > .handles > * > * > img { height: 0.875em; }
}


/* all portrait */
@media screen and (orientation: portrait) {
    /* attendee/ coordinator */
    .card.attendee,
    .card.coordinator { padding: 0.25rem 1.25rem 0.25rem 0.25rem; }
    .card.attendee > .image,
    .card.coordinator > .image {
        height: 2.5rem;
        width: 2.5rem;
    }
    .card.attendee > .details > .name,
    .card.coordinator > .details > .name { font-size: 0.85rem; }


    /* event */
    .card.event {
        aspect-ratio: initial;
        padding: 1.5rem 0.5rem 0.5rem;
    }
    .card.event > .date {
        font-size: 1.125rem;
        font-weight: 750;
    }
    .card.event > .date::after {
        height: 0.2rem;
        width: 12.5%;
    }
    .card.event > .flier {
        height: 6.75rem;
        margin-top: 1rem;
    }
    .card.event > .flier > .name {
        font-size: 1.125rem;
        font-weight: 500;
        padding: 0.25rem;
    }


    /* event-ticket-sales */
    .card.event-ticket-sales { padding: 0.25rem; }


    /* product */
    .card.product { padding: 0.5rem; }
    .card.product > .image {
        flex: 1.25 0 0px;
        padding: 1rem;
    }
    .card.product > .briefing { flex: 1.25 0 0px; }


    /* purchases */
    .card.purchase {
        gap: 1rem;
        padding: 1rem;
    }
    .card.purchase > .text { gap: 0.25rem; }


    /* vendor */
    .card.vendor { aspect-ratio: initial; }
    .card.vendor > .handles > * > * > img { height: 0.875em; }
}