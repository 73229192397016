[ data-page-name = "coordinators" ] #collection > * { aspect-ratio: initial; }



@media screen and (orientation: landscape) {
    [ data-page-name = "coordinators" ] #content {
        display: flex;
        overflow-x: hidden;
        white-space: nowrap;
    
    }
}



@media screen and (orientation: portrait) {
    [ data-page-name = "coordinators" ] #collection { grid-template-columns: 1fr !important; }
}