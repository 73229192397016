.nav {
    background: var(--THEME-NAV-BACKGROUND);
    font-size: 0.875rem;
}
.nav a {
    color: var(--theme-text-default);
    text-decoration: none;
}



/* dynamic */
#dynamic {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    height: 100%;
    padding-block: 1rem;
}
#dynamic * { cursor: default; }

#dynamic > * { padding-inline: 1rem; }

#dynamic > .head {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
#dynamic > .head > * {
    cursor: pointer;
    height: 1.5em;
}
#dynamic > .head > .back img { height: 100%; }

#dynamic > .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 5rem;
    overflow-y: auto;
}
#dynamic > .body > .links {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
}
#dynamic > .body > .links .link { cursor: pointer; }
#dynamic > .body > .links .link.active { color: var(--theme-text-bold); }
#dynamic > .body > .links > .link-combo > .dom {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
#dynamic > .body > .links > .link-combo > .dom > .link { flex-grow: 1; }
#dynamic > .body > .links > .link-combo > .dom > .controller > img {
    height: 1em;
    transition: 0.25s;
}
#dynamic > .body > .links > .link-combo.expanded > .dom > .controller > img { transform: rotateX(180deg); }
#dynamic > .body > .links > .link-combo > .subs { display: none; }
#dynamic > .body > .links > .link-combo.expanded > .subs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
#dynamic > .body > .links > .link-combo.expanded > .subs > * { padding-inline-start: 1rem; }



/* ribbon */
#ribbon {
    align-items: center;
    border-radius: 2rem;
    display: flex;
    flex-shrink: 0;
    gap: 1rem;
    height: 3rem;
    overflow-x: auto;
    padding: 0.5rem;
    position: relative;
    white-space: nowrap;
}
#ribbon::-webkit-scrollbar { height: 0; }
#ribbon form {
    background: rgba(255, 255, 255, 0.125);
    border-radius: 2rem;
    flex-direction: row !important;
    max-width: 50%;
    padding-inline: 1rem;
}
#ribbon button,
#ribbon input {
    background: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.438);
    outline: none;
}
#ribbon input { flex-grow: 1; }
#ribbon button > img { height: 0.875rem; }
#ribbon > * {
    align-items: center;
    display: flex;
    height: 2rem;
}


/* switch bar */
.switch-bar,
.switch-bar > .options {
    align-items: center;
    display: flex;
}

.switch-bar {
    border-radius: 0.5rem;
    justify-content: space-between;
    padding-inline: 1rem;
    width: 100%;
}
.switch-bar > .active { font-weight: 750; }
.switch-bar > .options { gap: 1rem; }
.switch-bar > .options > * {
    cursor: pointer;
    padding: 1rem;
}



/* landscape orientations */
@media screen and (orientation: landscape) {
    /* dynamic */
    #dynamic > .head > .brand { flex-grow: 1; }
    #dynamic > .head > .menu { display: none; }
}


/* small width landscape */
@media screen and (orientation: landscape) and (max-width: 1081px) {
    #dynamic { gap: 2rem; }

    #dynamic > .head > .back img { height: 1em; }
}



/* portrait orientations */
@media screen and (orientation: portrait) {
    /* dynamic */
    #dynamic > .head { z-index: var(--LAYER-NAV-HEAD); }

    #dynamic > .body { display: none; }
    #dynamic.expanded > .body {
        background: var(--THEME-NAV-BACKGROUND);
        display: flex;
        height: 100%;
        left: 0;
        padding: 5rem 1rem 1rem 1rem;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: var(--LAYER-NAV-BODY);
    }
}